<template>
  <div class="home">
    <div class="container1">
      <div class="top">
        <img
          class="top_rule animate__animated animate__fadeIn animate__delay-0.5s"
          src="../assets/top.png"
          alt=""
          srcset=""
        />
        <img
          class="top_tip animate__animated animate__fadeIn animate__delay-0.5s"
          src="../assets/content_1.png"
          alt=""
          srcset=""
        />
        <img
          class="top_product animate__animated animate__fadeIn animate__delay-0.5s"
          src="../assets/top_product.png"
          alt=""
          srcset=""
        />
      </div>
      <img
        class="act_test1 animate__animated animate__fadeIn "
        src="../assets/content_2.png"
        alt=""
        srcset=""
      />
      <img
        class="act_test2 animate__animated animate__fadeIn "
        src="../assets/content_3.png"
        alt=""
        srcset=""
      />
      <img
        class="act_test3 animate__animated animate__fadeIn "
        src="../assets/content_4.png"
        alt=""
        srcset=""
      />
      <img
        class="act_test4 animate__animated animate__fadeIn"
        src="../assets/content_5.png"
        alt=""
        srcset=""
      />
      <img class="act_bottom" src="../assets/logo@2x.png" alt="" srcset="" />
    </div>
    <!-- <img class="bg" src="../assets/bg.png" alt="" srcset="" /> -->
  </div>
</template>

<script>
import 'animate.css';

export default {
  name: 'Home',
};
</script>

<style lang="less">
.home {
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.bg {
  width: 100%;
  height: auto;
}
.container1 {
  // position: absolute;
  // z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 58px;
  &_rule {
    width: 347px;
    height: auto;
    margin: auto;
    animation-duration: 2s
  }
  &_tip {
    // width: 623px;
    width: 100%;
    margin-top: 39px;
    animation-duration: 2s
  }
  &_product {
    width: 70.6px;
    height: 191.7px;
    position: absolute;
    top: 35px;
    right: 123px;
    animation-duration: 2s
  }
}
.act_intro {
  width: 735px;
  margin-top: 28px;
}
.act_explain {
  width: 600px;
  margin: 34px;
}
.act_sub_title {
  width: 380px;
  margin-top: 71px;
}
.act_1 {
  width: 703px;
  margin: 26px;
}
.line {
  width: 701px;
  margin-top: 70px;
}
.act_2 {
  width: 703px;
  margin-top: 63px;
}
.logo {
  width: 750px;
  margin-top: 50px;
}

.act_test1 {
  margin-top: 30px;
  width: 432px;
  height: auto;
  animation-duration: 3s
}
.act_test2 {
  margin-top: 33px;
  width: 589px;
  height: auto;
  animation-duration: 4s
}
.act_test3 {
  margin-top: 46px;
  width: 614px;
  height: auto;
  animation-duration: 4s
}
.act_test4 {
  margin-top: 32px;
  width: 710px;
  height: auto;
  animation-duration: 5s
}

.act_bottom {
  width: 100%;
  margin-top: 64px;
}
</style>
