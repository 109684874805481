<template>
  <div class="loading">
    <div class="ani" ref="ani"></div>
    <div class="container">
      <div class="top">
        <img src="../assets/loading/ing.png" alt="" srcset="">
      </div>
      <div class="box">
        <div class="progress" :style="{width:`${progress}%`}"></div>
      </div>
      <div class="bottom">
        {{progress}}%
        <img src='../assets/loading/ing_line.png' alt="" srcset="">
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';

const imgs = [
  require('../assets/loading/baby1.png'),
  require('../assets/loading/baby2.png'),
  require('../assets/loading/draw.png'),
  require('../assets/loading/lbg.png'),
  require('../assets/loading/plane.png'),
  require('../assets/bg.png'),
  require('../assets/line.png'),
  require('../assets/logo.png'),
  require('../assets/top.png'),
  require('../assets/top_product.png'),
  require('../assets/content_1.png'),
  require('../assets/content_2.png'),
  require('../assets/content_3.png'),
  require('../assets/content_4.png'),
  require('../assets/content_5.png'),
  require('../assets/logo@2x.png'),
];

export default {
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    this.imgLoader(imgs, (e) => {
      this.progress = Math.round(e * 100);
      console.log('🚀 ~ file: loading.vue ~ line 49 ~ this.imgLoader ~ e', e);
      this.$emit('loadComplete');
    });
    const { ani } = this.$refs;
    lottie.loadAnimation({
      container: ani, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: './data.json', // the path to the animation json
    });
  },
  methods: {
    /**
     * 图片预加载
     * @return {function}
     * imgList 需要预加载的图片地址
     * callback 图片加载完成后的回掉，可获取到加载进度值
     * timeout  图片加载等待时间
     */
    imgLoader(imgList, callback, timeoutValue) {
      const timeout = timeoutValue || 5000;
      const total = imgList.length;
      let loaded = 0;
      const imgages = [];
      const onHander = function () {
        if (loaded < total) {
          ++loaded;
          if (callback) {
            callback(loaded / total);
          }
        }
      };

      if (!total) {
        return callback && callback(1);
      }

      for (let i = 0; i < total; i++) {
        imgages[i] = new Image();
        imgages[i].onload = onHander;
        imgages[i].onerror = onHander;
        imgages[i].src = imgList[i];
      }

      // 指定时间内图片没有加载完，不在等待
      const timer = setTimeout(() => {
        if (loaded < total) {
          loaded = total;
          if (callback) {
            callback(loaded / total);
          }
        }
        clearTimeout(timer);
      }, timeout * total);
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  height: 100%;
  overflow: hidden;
  background-image: url("../assets/loading/lbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue;
  background-color: #046cb2;
  font-size: 32px;
}
.container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ani {
  margin-top: 50%;
  position: relative;
  width: 750px;
  height:500px;
  .baby1 {
    position: absolute;
    bottom: 80px;
    width: 132.5px;
    right: 87.5px;
  }
  .baby2 {
    width: 132.5px;
  }
  .plane {
    width: 337.5px;
  }
  .draw {
    width: 100%;
  }
}
.box{
    width: 500px;
    height: 30px;
    background: #0263A3;
    border-radius: 30px;
    position: relative;
    margin: 10px 0;
    overflow: hidden;
}
.progress{
    height: 100%;
    background: #008FD8;
}

.lodingGif{
  width: 750px;
  height: auto;
}
.top{
  img{
    width: 193px;
    height: auto;
    margin-bottom: 10px;
  }
}
.bottom{
  text-align: center;
  position: relative;
  font-weight: 600;
  img{
    width: 208px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%,-50%);
  }
}
</style>
